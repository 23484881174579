<template>
  <div class="downloadPhone">
    <div class="NavBorder"></div>
    <div class="cont">
      <div class="logo">
        <img src="../assets/imgs/downloadPhone/logo.png" alt="" />
      </div>
      <div class="divide">
        <img src="../assets/imgs/downloadPhone/divide_img.png" alt="" />
      </div>
      <h2>事件日历提醒和人脉管理的效率工具</h2>
      <p>
        WOLB缩写自“Well Organized Life Booster”， 中译为“高效人生加速器”
      </p>
      <div class="downloadIOS" @click="downloadIOS">
        iOS版下载
      </div>
      <div class="downloadAndroid" @click="downloadAnd">
        Android版下载
      </div>
      <div class="downloadMac" @click="downloadMac">
        Mac公测版
      </div>
      <div class="downloadWindows" @click="downloadWin">
        Windows公测版
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Download",
  setup() {
    const downloadIOS = (item) => {
      window.location.href = "https://apps.apple.com/us/app/id1562940514";
    };

    const downloadAnd = (item) => {
      window.location.href = "https://file.w-o-l-b.com/wolb.apk";
    };
    const downloadWin = (item) => {
      window.location.href = 'https://www.w-o-l-b.com/pc/WOLB-beta.exe'
    };
    const downloadMac = (item) => {
      window.location.href = 'https://www.w-o-l-b.com/pc/WOLB-beta.dmg'
    };
    return {
      downloadIOS,
      downloadAnd,
      downloadWin,
      downloadMac
    };
  },
  mounted() { },
};
</script>

<style lang="scss" scoped>
.NavBorder {
  position: fixed;
  height: 3px;
  width: 100%;
  left: 0;
  top: 110px;
  background-color: #eeeeee;
  z-index: 2221;
}

.cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 157px;
  margin-bottom: 93px;
}

.logo {
  img {
    width: 187px;
  }
}

.divide {
  margin-top: 44px;

  img {
    width: 838px;
  }
}

h2 {
  font-weight: 600;
  font-size: 49px;
  line-height: 68px;
  margin-top: 44px;
}

p {
  color: #666666;
  font-size: 33px;
  width: 882px;
  padding: 10px 80px;
  text-align: center;
  line-height: 64px;
  margin-top: 3px;
  margin-bottom: 108px;
}

.downloadIOS,
.downloadAndroid,
.downloadMac,
.downloadWindows {
  width: 488px;
  height: 113px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 56px;
  box-shadow: 0px 10px 14px 1px rgba(215, 222, 249, 1);
  color: #fff;
  background-color: #5a76e4;
  font-size: 33px;
  margin-top: 49px;

  &::before {
    content: "";
    display: inline-block;
    height: 58px;
    background-size: 100% 100%;
  }
}

.downloadIOS::before {
  width: 46px;
  background-image: url("../assets/imgs/downloadPhone/ios_icon.png");
  margin-right: 33px;
}

.downloadAndroid::before {
  width: 60px;
  background-image: url("../assets/imgs/downloadPhone/android_icon.png");
  margin-right: 19px;
}

.downloadMac::before {
  width: 45px;
  background-image: url("../assets/imgs/downloadPhone/mac_icon.png");
  margin-right: 33px;
}

.downloadWindows::before {
  width: 53px;
  background-image: url("../assets/imgs/downloadPhone/windows_icon.png");
  margin-right: 33px;
}
</style>
